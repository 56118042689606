import { LocationGenerics } from "routes";

import { IntercomLauncherWrapper } from "@/components/modules/Intercom/IntercomLauncher";
import { IntegrationsProvider } from "@/integrations";
import { WELD_BIGQUERY_ID } from "@/pages/Setup/constants";
import ConnectDataWarehouseProvider from "@/pages/Setup/contexts/ConnectDataWarehouseProvider";
import SetupLayout from "@/pages/Setup/layouts/SetupLayout";
import { generateCreateWorkspaceRoutes, loadRoute } from "@/pages/Setup/routes";
import { RouteAwareAccountProvider } from "@/providers/account";
import { ApolloClient } from "@apollo/client";
import { Outlet, Route } from "@tanstack/react-location";

import WorkspacesPage from "./WorkspacesPage";

export const workspacesRoute = (
  client: ApolloClient<object>,
): Route<LocationGenerics> => {
  return {
    path: "workspaces",
    element: (
      <IntercomLauncherWrapper>
        <Outlet />
      </IntercomLauncherWrapper>
    ),
    children: [
      {
        path: "create",
        element: (
          <SetupLayout showWorkspaceSwitcher>
            <Outlet />
          </SetupLayout>
        ),
        children: [generateCreateWorkspaceRoutes()],
      },
      {
        path: ":slug",
        element: (
          <RouteAwareAccountProvider paramName="slug">
            <IntegrationsProvider>
              <SetupLayout showWorkspaceSwitcher>
                <Outlet />
              </SetupLayout>
            </IntegrationsProvider>
          </RouteAwareAccountProvider>
        ),
        children: [
          {
            /*
              /workspaces/:slug/connect-dw is for already created accounts
              that have yet to connect a data warehouse
            */
            path: "connect-dw",
            element: (
              <ConnectDataWarehouseProvider>
                <Outlet />
              </ConnectDataWarehouseProvider>
            ),
            children: [
              {
                id: "configure-weld-bq",
                search: (search) => {
                  return search.integrationId === WELD_BIGQUERY_ID;
                },
                import: loadRoute(
                  (res) => res.default.ConfigureWeldBigQueryPage,
                ),
              },
              {
                id: "configure-dw",
                search: (search) => {
                  return search.integrationId !== undefined;
                },
                import: loadRoute(
                  (res) => res.default.ConfigureDataWarehousePage,
                ),
              },
              {
                id: "select-dw",
                import: loadRoute(
                  (res) =>
                    res.default.SelectDataWarehouseForExistingWorkspacePage,
                ),
              },
            ],
          },
        ],
      },
      {
        element: <WorkspacesPage />,
      },
    ],
  };
};
