import SimplePageLayout from "@/layouts/SimplePageLayout";
import { Helmet } from "react-helmet-async";

import { WorkspacesOverview } from "./WorkspacesOverview";

export default function WorkspacePage() {
  const title = "Select your account";
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SimplePageLayout>
        <WorkspacesOverview />
      </SimplePageLayout>
    </>
  );
}
