import { Navigate } from "@tanstack/react-location";
import Center from "@/components/elements/layout/Center";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { useMountEffect } from "@/hooks/useMountEffect";
import { useCurrentUser } from "@/providers/UserProvider";
import { useFeatureFlagContext } from "@/features/feature-flags";
import { useState } from "react";
import { connectDWRoute } from "@/router";

import { useCurrentAccount } from "./AccountProvider";

export function AccountValidator(props: { children: JSX.Element }) {
  const user = useCurrentUser();
  const account = useCurrentAccount();
  const [featureFlagsReady, setFeatureFlagsReady] = useState(false);
  const { updateContext } = useFeatureFlagContext();

  useMountEffect(async () => {
    await updateContext({
      key: user.id,
      email: user.email,
      accountId: account.id,
      slug: account.slug,
    });
    setFeatureFlagsReady(true);
  });

  if (!account.dataWarehouseConnectionId) {
    // Redirect to data warehouse setup up if workspace doesn't have a connection set up
    return <Navigate to={connectDWRoute(account.slug)} replace search />;
  }

  if (!featureFlagsReady) {
    return (
      <Center className="absolute inset-0">
        <LoadingSpinner />
      </Center>
    );
  }
  return props.children;
}
