import { createContext, useContext } from "react";

import { useMountEffect } from "@/hooks/useMountEffect";
import { IntegrationType, useIntegration } from "@/integrations";

import { useCurrentAccount } from "./account";

const DataWarehouseContext = createContext<{
  integration: IntegrationType;
} | null>(null);

export function useDataWarehouseContext() {
  const context = useContext(DataWarehouseContext);
  if (!context) {
    throw new Error(
      "useDataWarehouseContext must be used within a DataWarehouseProvider",
    );
  }
  return context;
}

export function DataWarehouseProvider(props: {
  children: React.ReactNode;
  onIntegrationNotFound?: (
    account: ReturnType<typeof useCurrentAccount>,
  ) => void;
}) {
  const account = useCurrentAccount();

  const integration = useIntegration(
    account.dataWarehouse?.integrationId ?? "",
  );

  useMountEffect(() => {
    if (!integration && props.onIntegrationNotFound) {
      props.onIntegrationNotFound(account);
    }
  });

  if (!integration) {
    return null;
  }

  return (
    <DataWarehouseContext.Provider value={{ integration }}>
      {props.children}
    </DataWarehouseContext.Provider>
  );
}
