import { GRAPHQL_BACKEND_URL } from "constants/index";
import { GraphQLError } from "graphql";
import React from "react";

import { isDevelopment } from "@/helpers/environment";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";

import { useCurrentAccountIdValue } from "./account/AccountProvider";

/* eslint-disable no-console */
function logGraphQLError(error: GraphQLError) {
  console.error("[GraphQL Error]", error.message, error);
}

function logNetworkError(error: NetworkError) {
  console.error("[NetworkError]", error?.message, error);
}
/* eslint-enable no-console */

export default function Provider({ children }: React.PropsWithChildren<{}>) {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const currentAccountIdRef = useLatestValueRef(useCurrentAccountIdValue());

  const client = React.useMemo(() => {
    // Using a retry link to retry failed requests
    // Using the default configuration (5 retries, 300ms delay and exponential backoff)
    // https://www.apollographql.com/docs/react/api/link/apollo-link-retry/#default-configuration
    const retryLink = new RetryLink();

    const httpLink = new HttpLink({
      uri: `${GRAPHQL_BACKEND_URL}/graphql`,
      credentials: "include",
    });

    const accountIdLink = setContext((_, prevContext) => {
      if (prevContext.skipXAccountIdHeader === true) {
        return prevContext;
      }
      const currentAccountId: string | null =
        prevContext.accountId || currentAccountIdRef.current;

      if (currentAccountId) {
        return {
          headers: {
            ...prevContext.headers,
            "x-account-id": currentAccountId,
          },
        };
      }
      return prevContext;
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          if (isDevelopment) {
            logGraphQLError(error);
          }
          if (error.extensions?.code === 441) {
            window.location.href =
              "/email-not-verified" + window.location.search;
          }
          const originalError = error.extensions.originalError;
          if (typeof originalError === "object" && originalError !== null) {
            if (
              "statusCode" in originalError &&
              originalError.statusCode === 401
            ) {
              if ("message" in originalError) {
                if (originalError.message === "Email not validated") {
                  window.location.href =
                    "/email-not-valid" + window.location.search;
                }
              }
            }
          }
        });
      }

      if (networkError) {
        if (isDevelopment) {
          logNetworkError(networkError);
        }
        const error = (networkError as any).error;
        if (error === "login_required" || error === "invalid_grant") {
          loginWithRedirect({
            authorizationParams: { prompt: "login" },
          });
          return;
        }
      }
    });

    return new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              chatThreads: offsetLimitPagination(),
            },
          },
        },
      }),
      link: from([errorLink, retryLink, authLink, accountIdLink, httpLink]),
      connectToDevTools: isDevelopment,
    });
  }, [getAccessTokenSilently, loginWithRedirect, currentAccountIdRef]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
