import { GTMContext, GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { ContextType } from "react";

const gtmParams: ContextType<typeof GTMContext> = {
  id: process.env.REACT_APP_GTM_ID as string,
  environment: {
    gtm_auth: process.env.REACT_APP_GTM_AUTH as string,
    gtm_preview: process.env.REACT_APP_GTM_PREVIEW as string,
  },
};

export default function WeldGTMProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <GTMProvider state={gtmParams}>{children}</GTMProvider>;
}
