import React from "react";

import { useGetAccountSlugLazyQuery } from "@/apollo/types";

import * as routeHelpers from "./routeHelpers";

export function useNavigateToWorkspace() {
  const [getAccountSlugLazy] = useGetAccountSlugLazyQuery();

  return React.useMemo(() => {
    const navigateBySlug = (slug: string, search?: Record<string, any>) => {
      window.location.href =
        routeHelpers.workspaceRoute(slug) +
        (search ? `?${new URLSearchParams(search)}` : "");
    };

    return {
      bySlug: navigateBySlug,
      byId: async (accountId: string, search?: Record<string, any>) => {
        try {
          const result = await getAccountSlugLazy({
            fetchPolicy: "network-only",
            variables: {
              id: accountId,
            },
          });
          if (result.data !== undefined) {
            await navigateBySlug(result.data.accountById.slug, search);
          }
        } catch {
          //
        }
      },
    };
  }, [getAccountSlugLazy]);
}
