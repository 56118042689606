import { Transition } from "@headlessui/react";
import { uuid4 } from "@sentry/utils";
import ToastNotification from "@/components/elements/ToastNotification";
import { Fragment, createContext, useCallback, useContext } from "react";
import { createPortal } from "react-dom";
import { useImmer } from "use-immer";

export type ToastType = {
  title: string;
  description: string | undefined;
  type: "error" | "success" | "warning" | "info";
  id: string;
  shown: boolean;
};

const ToastContext = createContext<
  (
    title: string,
    description: string | undefined,
    type: ToastType["type"],
    duration?: number,
  ) => any
>(() => null);

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useImmer<ToastType[]>([]);

  const fn = useCallback(
    (
      title: string,
      description: string | undefined,
      type: ToastType["type"] = "error",
      duration = 5000,
    ) => {
      const id = uuid4();
      setToasts((draft) => {
        draft.push({ title, description, type, id, shown: true });
      });
      setTimeout(
        () =>
          setToasts((draft) => {
            const t = draft.find((t) => t.id === id);
            if (t) t.shown = false;
          }),
        duration,
      );
    },
    [setToasts],
  );

  return (
    <ToastContext.Provider value={fn}>
      {createPortal(
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 z-[9999] flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            {toasts.map((t) => (
              <Transition
                key={t.id}
                show={t.shown}
                appear
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-6 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() =>
                  setToasts((draft) =>
                    draft.filter((toast) => toast.id !== t.id),
                  )
                }
              >
                <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:border-gray-700 dark:bg-gray-700 dark:text-white">
                  <ToastNotification
                    toast={t}
                    onClose={() =>
                      setToasts((draft) => {
                        const toast = draft.find((toast) => toast.id === t.id);
                        if (toast) toast.shown = false;
                      })
                    }
                  />
                </div>
              </Transition>
            ))}
          </div>
        </div>,
        document.body,
      )}

      {children}
    </ToastContext.Provider>
  );
};
