import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { Role, useCurrentUserRoleQuery } from "@/apollo/types";
import { atom, useAtom, useAtomValue } from "jotai";
import { atomWithStorage, useResetAtom } from "jotai/utils";
import { useMixpanel } from "@/monitoring/mixpanel";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { createStorageKey } from "@/utils/storage";

import { AccountType, useUserAccounts } from "../UserProvider";

export const AccountContext = createContext<{
  account: AccountType;
  role: Role;
} | null>(null);

/**
 * Keeps track of the most recently viewed workspace and is persisted in localStorage
 * Will be used initially on app load to redirect the user to given workspace when the user accesses the app
 * from the root url, i.e. workspace.weld.app
 */
const mostRecentAccountId = atomWithStorage<string | null>(
  createStorageKey("most-recent-account-id"),
  null,
);

export function useMostRecentAccountId() {
  return useAtomValue(mostRecentAccountId);
}

export function useResetMostRecentAccountId() {
  return useResetAtom(mostRecentAccountId);
}

/**
 * Provides app wide access to the current account id.
 * This is currently here so that the ApolloProvider can
 * populate the x-account-id header.
 * Should be removed as soon as possible.
 */
const currentAccountIdAtom = atom<string | null>(null);

function useCurrentAccountIdState() {
  return useAtom(currentAccountIdAtom);
}

export function useCurrentAccountIdValue() {
  return useCurrentAccountIdState()[0];
}

type AccountProviderProps = {
  accountId?: string;
  onInvalidAccount?: (accountId: string) => void;
};

export const AccountProvider = ({
  accountId,
  onInvalidAccount,
  children,
}: PropsWithChildren<AccountProviderProps>) => {
  const accounts = useUserAccounts();

  const currentAccount: AccountType | null = useMemo(
    () => accounts.find((account) => account.id === accountId) ?? null,
    [accounts, accountId],
  );

  const [_currentAccountId, setCurrentAccountId] = useCurrentAccountIdState();
  const [, setMostRecentAccountId] = useAtom(mostRecentAccountId);

  const { data: userRoleData } = useCurrentUserRoleQuery({
    context: {
      accountId: currentAccount?.id,
    },
    skip: !_currentAccountId,
  });
  const userRole = userRoleData?.current?.role as Role | undefined;

  useEffect(() => {
    if (accountId === undefined) return;
    if (accounts.length === 0) return;

    const isValidAccountId = (accountId: string) => {
      return accounts.find((x) => x.id === accountId) !== undefined;
    };

    if (isValidAccountId(accountId)) {
      setCurrentAccountId(accountId);
      setMostRecentAccountId(accountId);
    } else {
      onInvalidAccount?.(accountId);
      return;
    }
  }, [
    accountId,
    accounts,
    onInvalidAccount,
    setCurrentAccountId,
    setMostRecentAccountId,
  ]);

  const mixpanel = useMixpanel();
  useEffect(() => {
    if (currentAccount === null) return;
    devLog(currentAccount);

    const { id, name, slug, dataWarehouse } = currentAccount;
    Sentry.setContext("workspace", {
      id,
      name,
      slug,
      integrationId: dataWarehouse?.integrationId,
    });

    datadogRum.setGlobalContextProperty("workspace", {
      id,
      name,
      slug,
      integrationId: dataWarehouse?.integrationId,
    });

    mixpanel.set_group("workspace", slug);
    mixpanel.register({
      workspace_id: id,
      workspace_name: name,
      workspace_slug: slug,
      workspace_integration_id: dataWarehouse?.integrationId ?? null,
    });
  }, [currentAccount, mixpanel]);

  const ctx = useMemo(() => {
    if (currentAccount === null || !userRole) return null;
    return {
      account: currentAccount,
      role: userRole,
    };
  }, [currentAccount, userRole]);

  if (!ctx) {
    return null;
  }

  return (
    <AccountContext.Provider value={ctx}>{children}</AccountContext.Provider>
  );
};

const useAccountContext = () => {
  const ctx = useContext(AccountContext);
  if (ctx == null) {
    throw new Error("AccountProvider not available");
  }
  return ctx;
};

export function useCurrentAccount() {
  return useAccountContext().account;
}

export const useUserRole = () => {
  return useAccountContext().role;
};

export function useDataWarehouse() {
  const dwh = useAccountContext().account.dataWarehouse;
  return {
    ...(dwh ?? {}),
    isManagedDataWarehouse: dwh?.integrationId === "weld-bigquery",
  };
}

function devLog(account: AccountType) {
  if (process.env.NODE_ENV === "development") {
    /* eslint-disable no-console */
    console.group("workspace");
    console.debug(account);
    console.groupEnd();
    /* eslint-enable no-console */
  }
}
