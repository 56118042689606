import { useFlags } from "launchdarkly-react-client-sdk";
import { Helmet } from "react-helmet-async";

import WeldLogo from "@/components/elements/Weldlogo";
import {
  Cog6ToothIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/solid";

export function MaintenanceProvider(props: { children: React.ReactNode }) {
  const { appDownForMaintenance } = useFlags();
  if (appDownForMaintenance) {
    return <MaintenancePage />;
  }
  return <>{props.children}</>;
}

function MaintenancePage() {
  return (
    <>
      <Helmet>
        <title>WELD - Maintenance</title>
      </Helmet>
      <div className="fixed inset-0">
        <div className="flex h-full w-full flex-col">
          <div className="mt-8 flex-none pb-4">
            <WeldLogo className="mx-auto h-3 w-auto" />
          </div>
          <div className="flex grow items-center justify-center">
            <div className="flex flex-col items-center px-20">
              <div className="relative flex h-36 w-36 items-center justify-center sm:h-48 sm:w-48 md:h-64 md:w-64">
                <Cog6ToothIcon className="absolute inset-0 h-full w-full animate-spin-super-slow text-gray-200 dark:text-gray-700" />
                <div className="z-10 h-2/5 w-2/5 bg-gray-200 dark:bg-gray-700">
                  <WrenchScrewdriverIcon className="h-full w-full text-black/50 dark:text-white/50" />
                </div>
              </div>
              <h1 className="mb-8 mt-4 text-2xl xs:text-4xl">
                We'll be back soon!
              </h1>

              <p className="text-center leading-relaxed">
                We're very sorry for the inconvenience but we're performing some
                maintenance at the moment. Please check back soon...
              </p>
              <p className="mt-6">
                Your syncs are not affected by this maintenance. They will
                continue to run as scheduled.
              </p>
              <p className="mt-8 italic">- The WELD Team</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
