import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import {
  CurrentUserWithAccountsQuery,
  useCurrentUserWithAccountsQuery,
} from "@/apollo/types";
import { useFeatureFlagContext } from "@/features/feature-flags";
import { useStonlyWidget } from "@/features/product-guide";
import { isDevelopment } from "@/helpers/environment";
import { pick } from "lodash";
import { useMixpanel } from "@/monitoring/mixpanel";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useLoading } from "./LoadingProvider";
import { useToast } from "./ToastProvider";

type UserData = CurrentUserWithAccountsQuery["current"];

export type UserType = Pick<
  UserData,
  "id" | "firstName" | "lastName" | "email" | "weldSuperAdmin"
>;

export type AccountType = NonNullable<UserData["accounts"]>[number] & {
  name: string;
};

type UserContextType = {
  user: UserType;
  accounts: AccountType[];
  refetchUserData: () => Promise<void>;
};

export const UserContext = createContext<UserContextType | null>(null);

export const UserProvider = (
  props: PropsWithChildren<{
    onUserLoaded?: (user: UserType) => void;
  }>,
) => {
  const toast = useToast();
  const StonlyWidget = useStonlyWidget();
  const mixpanel = useMixpanel();

  const { updateContext: updateFeatureFlagContext } = useFeatureFlagContext();

  const { data, refetch } = useCurrentUserWithAccountsQuery({
    fetchPolicy: "network-only",
    context: {
      skipXAccountIdHeader: true,
    },
    onError: () => {
      toast("Failed to load user", "Please refresh your browser", "error");
    },
    onCompleted: (data) => {
      const user = data.current;
      devLog(user);

      updateFeatureFlagContext({
        key: user.id,
        email: user.email,
      });
      props.onUserLoaded?.(user);

      datadogRum.setUser({
        id: user.id,
        email: user.email,
      });
      StonlyWidget("identify", user.email, {
        id: user.id,
      });
      mixpanel.identify(user.id);
      mixpanel.people.set("$email", user.email);
      mixpanel.people.set("$name", `${user.firstName} ${user.lastName}`);
      if (user.email.endsWith("@weld.app") && !isDevelopment) {
        mixpanel.people.set("$avatar", window.location.origin + "/logo192.png");
      }

      Sentry.setUser({
        email: user.email,
        username: `${user.firstName} ${user.lastName}`,
        id: user.id,
      });
    },
  });

  const userData = data?.current;

  useEffect(() => {
    const listener = async () => {
      try {
        await refetch();
      } catch (err) {
        //
      }
    };
    window.addEventListener("focus", listener);
    return () => window.removeEventListener("focus", listener);
  }, [refetch]);

  const context = useMemo(() => {
    if (!userData) return null;
    const user = pick(userData, [
      "id",
      "firstName",
      "lastName",
      "email",
      "weldSuperAdmin",
    ]);

    const accounts = (userData.accounts ?? []).map((x) => ({
      ...x,
      name: x.name ?? "",
    }));
    return {
      user,
      accounts,
      refetchUserData: async () => {
        await refetch();
      },
    };
  }, [userData, refetch]);

  useLoading(!userData);

  if (!context) return null;

  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
};

const useUserDataContext = () => {
  const ctx = useContext(UserContext);
  if (ctx == null) {
    throw new Error("UserProvider not available");
  }
  return ctx;
};

export function useUserAccounts() {
  return useUserDataContext().accounts;
}

export function useCurrentUser() {
  return useUserDataContext().user;
}

export function useRefetchUserData() {
  return useUserDataContext().refetchUserData;
}

function devLog(user: UserType) {
  if (process.env.NODE_ENV === "development") {
    /* eslint-disable no-console */
    console.group("user");
    console.debug(user);
    console.groupEnd();
    /* eslint-enable no-console */
  }
}
