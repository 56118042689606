import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { WeldLoader } from "@/components/elements/WeldLoader";

const Context = createContext({
  loading: false,
  increment: () => {},
  decrement: () => {},
});

export const useLoading = (isLoading: boolean) => {
  const localLoadingCount = useRef(0);

  const { increment, decrement } = useContext(Context);

  useEffect(() => {
    return () => {
      if (localLoadingCount.current > 0) {
        // decrement on unmount if we are still loading
        decrement();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading) {
      increment();
      localLoadingCount.current += 1;
    } else {
      if (localLoadingCount.current > 0) {
        // only decrement if we ever incremented
        decrement();
        localLoadingCount.current -= 1;
      }
    }
  }, [isLoading, increment, decrement]);
};

export const useLoadingContext = () => {
  return useContext(Context);
};

const LoadingProvider = (props: PropsWithChildren<{}>) => {
  const [state, setState] = useState(0);
  const increment = useCallback(() => setState((prev) => prev + 1), []);
  const decrement = useCallback(
    () => setState((prev) => Math.max(0, prev - 1)),
    [],
  );
  const context = useMemo(
    () => ({
      loading: state > 0,
      increment,
      decrement,
    }),
    [decrement, increment, state],
  );
  return <Context.Provider value={context}>{props.children}</Context.Provider>;
};

export default LoadingProvider;

const withLoadingProvider = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> => {
  return function WithLoadingProvider(props: P): JSX.Element {
    return (
      <LoadingProvider>
        <Component {...props} />
      </LoadingProvider>
    );
  };
};

export const WeldLoadingProvider = withLoadingProvider(
  (props: PropsWithChildren<{}>) => {
    const context = useLoadingContext();
    return (
      <>
        {context.loading && (
          <div className="fixed inset-0 z-50 bg-gray-100 dark:bg-background">
            <WeldLoader />
          </div>
        )}
        {props.children}
      </>
    );
  },
);
