import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import React from "react";
import { history } from "routes";

function Auth0ProviderWithHistory({ children }: React.PropsWithChildren<{}>) {
  // Retrieve the previously created environment variables
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  // Fail fast if the environment variables aren't set
  if (!domain || !clientId)
    throw new Error(
      "Please set REACT_APP_AUTH0_DOMAIN and REACT_APP_AUTH0_CLIENT_ID env. variables",
    );

  const onRedirectCallback: Auth0ProviderOptions["onRedirectCallback"] = (
    appState,
  ) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation="memory"
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
