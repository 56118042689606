import { noop } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import useLocalStorageState, {
  LocalStorageState,
} from "use-local-storage-state";

import { createStorageKey } from "@/utils/storage";

const DarkModeContext = createContext<{
  isDarkModeEnabled: boolean;
  setIsDarkModeEnabled: LocalStorageState<boolean>[1];
}>({
  isDarkModeEnabled: false,
  setIsDarkModeEnabled: noop,
});

const getColorSchemeQuery = () => {
  if (window.matchMedia) {
    return window.matchMedia("(prefers-color-scheme: dark)");
  }
  return null;
};

export function DarkModeProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const [isDarkModeEnabled, setIsDarkModeEnabled] = useLocalStorageState(
    createStorageKey("dark-mode"),
    {
      defaultValue: getColorSchemeQuery()?.matches ?? false,
    },
  );

  useEffect(() => {
    const colorSchemeQuery = getColorSchemeQuery();
    if (colorSchemeQuery) {
      const changeHandler = (event: MediaQueryListEvent) => {
        setIsDarkModeEnabled(event.matches);
      };

      /**
       * Optional chaining due to Safari < v14 not implementing `addEventListener` on `MediaQueryList`
       **/
      colorSchemeQuery.addEventListener?.("change", changeHandler);
      return () => {
        colorSchemeQuery.removeEventListener?.("change", changeHandler);
      };
    }
  }, [setIsDarkModeEnabled]);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", isDarkModeEnabled);
  }, [isDarkModeEnabled]);

  const context = useMemo(
    () => ({ isDarkModeEnabled, setIsDarkModeEnabled }),
    [isDarkModeEnabled, setIsDarkModeEnabled],
  );

  return (
    <DarkModeContext.Provider value={context}>
      {children}
    </DarkModeContext.Provider>
  );
}

export const useDarkMode = () => useContext(DarkModeContext);

export function useColorModeValue<T>(lightValue: T, darkValue: T) {
  const { isDarkModeEnabled } = useDarkMode();
  return isDarkModeEnabled ? darkValue : lightValue;
}

export function useGetColorModeValue() {
  const { isDarkModeEnabled } = useDarkMode();
  return useCallback(
    function <T>(lightValue: T, darkValue: T) {
      return isDarkModeEnabled ? darkValue : lightValue;
    },
    [isDarkModeEnabled],
  );
}
