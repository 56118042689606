import * as routeHelpers from "./routeHelpers";

export function LinkToWorkspace(
  props: { slug: string } & Omit<React.HTMLProps<HTMLAnchorElement>, "href">,
) {
  const { slug, ...restProps } = props;
  return (
    <a {...restProps} href={routeHelpers.workspaceRoute(props.slug)}>
      {restProps.children}
    </a>
  );
}
